import { Controller } from '@hotwired/stimulus'

import L from 'leaflet'

export default class extends Controller {
  static targets = ["map", "lat", "lon"]

  connect () {
    this.createMap()
    this.updateLocation()
  }

  updateLocation () {
    if (this.hasLocation) {
      const lnglat = this.lnglat
      this.map.setView(lnglat, 18)

      this.marker.setLatLng(lnglat)
    } else {
      this.map.setView([0, 0], 2)
    }
  }

  createMap () {
    this.map = L.map(this.mapTarget, {
      zoomControl: false,
      attributionControl: false
    })

    L.control.zoom({ position: 'topright' }).addTo(this.map)
    L.control.attribution({ prefix: false }).addTo(this.map)

    L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.MAPBOX_TOKEN}`, {
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      id: 'mapbox/streets-v11'
    }).addTo(this.map)

    this.map.on('click', (e) => this.setLocation(e))

    if (this.hasLocation) {
      this.createMarker()
    }
  }

  createMarker () {
    this.marker = L.marker(this.lnglat, {
      icon: L.icon({
        iconUrl: require('images/markers/fixed.png'),
        iconRetinaUrl: require('images/markers/fixed-2x.png'),
        iconSize: [23, 36],
        iconAnchor: [12, 24]
      })
    }).addTo(this.map)
  }

  setLocation (e) {
    const lat = e.latlng.lat
    const lon = e.latlng.lng

    this.latTarget.value = lat.toFixed(7)
    this.lonTarget.value = lon.toFixed(7)

    if (!this.marker) {
      this.createMarker()
    }

    this.marker.setLatLng(e.latlng)
  }

  get hasLocation () {
    return this.latTarget.value && this.lonTarget.value
  }

  get lnglat () {
    const lat = this.latTarget.value
    const lon = this.lonTarget.value

    return [lat, lon]
  }
}
