// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Dropzone from 'dropzone'
import 'bootstrap'
import 'controllers'
import 'channels'

Rails.start()

Dropzone.options.importDropzone = {
  acceptedFiles: 'application/zip',
  maxFilesize: 2048,
  dictDefaultMessage: 'Drop ZIP files from your PUC’s SD card here to upload'
}
