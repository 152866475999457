import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds"]
  static values = { date: String }

  connect () {
    if (this.hasDateValue) {
      this.endTime = new Date(this.dateValue).getTime()
      this.timer = setInterval(() => this.update(), 1000)
      this.update()
    }
  }

  disconnect () {
    this.stopTimer()
  }

  stopTimer () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  update () {
    const difference = this.endTime - new Date().getTime()

    if (difference < 0) {
      this.stopTimer()
      this.displayTime(0, 0, 0, 0)
      return
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((difference % (1000 * 60)) / 1000)

    this.displayTime(days, hours, minutes, seconds)
  }

  displayTime (days, hours, minutes, seconds) {
    this.daysTarget.textContent = days
    this.hoursTarget.textContent = hours
    this.minutesTarget.textContent = minutes
    this.secondsTarget.textContent = seconds
  }
}
