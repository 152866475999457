import { Controller } from '@hotwired/stimulus'

import Tooltip from 'bootstrap/js/dist/tooltip'

export default class extends Controller {
  static targets = ["source", "trigger"]

  connect () {
    this.tooltip = new Tooltip(this.triggerTarget, {
      title: 'Copied!',
      trigger: 'manual'
    })
  }

  copy () {
    const source = this.sourceTarget.innerText || this.sourceTarget.value
    navigator.clipboard.writeText(source).then(() => this.copied())
  }

  copied () {
    this.tooltip.show()
    setTimeout(() => this.tooltip.hide(), 2000)
  }
}
